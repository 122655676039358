import Vue from 'vue'
import App from './App.vue'
import './assets/tailwind.css'
import SimpleAnalytics from "simple-analytics-vue";

Vue.config.productionTip = false
Vue.use(SimpleAnalytics, { domain: "sa.invii.io" });

new Vue({
  render: h => h(App),
}).$mount('#app')
