<template>
  <div
    class="min-h-full min-w-full bg-landingPageBackground overflow-hidden bg-cover"
  >
    <header class="relative h-screen bg-blue-gray-800 ">
      <div class="relative">
        <nav
          class="relative max-w-7xl mx-auto flex items-center justify-between pt-6 pb-2 px-4 2xl:pt-32 lg:pt-22 sm:px-6 lg:px-8"
          aria-label="Global"
        >
          <div class="flex items-center flex-1">
            <div class="flex items-center justify-between w-full lg:w-auto">
              <a href="#">
                <span class="sr-only">Invii</span>
                <img
                  class="h-14 w-auto sm:h-14"
                  src="../assets/main_transparent.svg"
                  alt=""
                />
              </a>
            </div>
          </div>
        </nav>
      </div>

      <div
        class="relative max-w-md mx-auto pt-32 md:pt-0 px-4 pb-32 sm:max-w-3xl sm:px-6 md:mt-32 lg:max-w-7xl lg:px-8"
      >
        <span class="sr-only">An easier way to order</span>

        <h1
          class="font-bold text-4xl tracking-tight text-white md:text-5xl lg:text-6xl "
        >
          An easier way to order.
        </h1>

        <p class="font-medium mt-6 max-w-3xl text-xl text-white">
          Invii is a revolutionary new way to handle customer ordering,
          payments, and analytics in restaurants. Stay tuned, we'll be launching soon.
        </p>
        <a href="https://twitter.com/GetInvii">
        <button
          type="button"
          class="inline-flex items-center mt-5 px-6 py-3 border border-transparent text-base font-medium rounded-md text-logoDarker bg-superLight hover:bg-logoHover hover:text-white focus:outline-none focus:ring-logo focus:ring-offset-2 focus:ring-logo"
        >
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fab"
            data-icon="twitter"
            class="-ml-1 mr-2 h-5 w-5"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              fill="currentColor"
              d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
            ></path>
          </svg>
          Follow us on Twitter
        </button>
        </a>
      </div>
      <svg
        class="absolute bottom-full left-full transform translate-y-1/3 -translate-x-2/3 xl:bottom-auto xl:top-0 xl:translate-y-0"
        width="404"
        height="384"
        fill="none"
        viewBox="0 0 404 384"
        aria-hidden="true"
      >
        <defs>
          <pattern
            id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
            x="0"
            y="0"
            width="20"
            height="20"
            patternUnits="userSpaceOnUse"
          >
            <rect
              x="0"
              y="0"
              width="4"
              height="4"
              class="text-white"
              fill="currentColor"
            />
          </pattern>
        </defs>
        <rect
          width="404"
          height="384"
          fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
        />
      </svg>
      <svg
        class="absolute top-full transform -translate-y-1/3 -translate-x-1/3 xl:-translate-y-1/2"
        width="404"
        height="384"
        fill="none"
        viewBox="0 0 404 384"
        aria-hidden="true"
      >
        <defs>
          <pattern
            id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
            x="0"
            y="0"
            width="20"
            height="20"
            patternUnits="userSpaceOnUse"
          >
            <rect
              x="0"
              y="0"
              width="4"
              height="4"
              class="text-white"
              fill="currentColor"
            />
          </pattern>
        </defs>
        <rect
          width="404"
          height="384"
          fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
        />
      </svg>
      <svg
        class="absolute top-full transform -translate-y-1/3 -translate-x-1/3 xl:-translate-y-1/2"
        width="404"
        height="384"
        fill="none"
        viewBox="0 0 404 384"
        aria-hidden="true"
      >
        <defs>
          <pattern
            id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
            x="0"
            y="0"
            width="20"
            height="20"
            patternUnits="userSpaceOnUse"
          >
            <rect
              x="0"
              y="0"
              width="4"
              height="4"
              class="text-white"
              fill="currentColor"
            />
          </pattern>
        </defs>
        <rect
          width="404"
          height="384"
          fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
        />
      </svg>
    </header>
  </div>
</template>

<script>
export default {
  name: "Landing",
  props: {
    msg: String,
  },
};
</script>
